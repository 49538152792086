// @js-import "@common/common-dependencies.js"
// @js-import "@shared/table-utils.js"

ComponentsManager.register('content', function() {

    return {
        init: function(_, $componentElement) {
            const $expandedWrapper = $componentElement.find($(".content_area").find(".expand_element"));
            rwd.basicExpandElementAnimation($expandedWrapper);
            rwd.basicExpandContentWCAGAnimation($componentElement);

            ckEditorPluginChangelog();
            ckEditorPluginExpand($componentElement);
        }
    };
});
